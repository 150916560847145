import { chakra, HTMLChakraProps } from '@chakra-ui/system'
import * as React from 'react'

export const Link = (props: HTMLChakraProps<'a'>) => (
  <chakra.a
    marginStart="1"
    href="#"
    color="brand.lightGreen"
    _hover={{ color: 'brand.darkGreen' }}
    display={{ base: 'block', sm: 'inline' }}
    {...props}
  />
)

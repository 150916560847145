import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react'
import { LoginForm } from '@components/LoginForm'
import { Card } from '@components/ui/Card'
import { DividerWithText } from '@components/ui/DividerWithText'
import { Link } from '@components/ui/Link'
import { Logo } from '@components/ui/Logo'
import { Provider } from '@supabase/supabase-js'
import { useUser } from '@utils/useUser'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { FaFacebook, FaGithub, FaGoogle } from 'react-icons/fa'

const SignIn = () => {
  const router = useRouter()
  const [, setLoading] = useState(false)
  const { user, signIn } = useUser()
  const [, setMessage] = useState({ type: '', content: '' })

  useEffect(() => {
    if (user) router.replace('/dashboard')
  }, [user])

  const handleOAuthSignIn = async (provider: Provider) => {
    setLoading(true)
    const { error } = await signIn({ provider })
    if (error) {
      setMessage({ type: 'error', content: error.message })
    }
    setLoading(false)
  }

  return (
    <Box
      bg={useColorModeValue('gray.50', 'inherit')}
      minH="100vh"
      py="12"
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <Flex justifyContent="center">
          <Logo mx="auto" h="8" mb={{ base: '10', md: '20' }} />
        </Flex>
        <Heading textAlign="center" size="xl" fontWeight="extrabold">
          Sign in to your account
        </Heading>
        <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
          <Text as="span">Don&apos;t have an account?</Text>
          <Link href="/signup">Register Now</Link>
        </Text>
        <Card>
          <LoginForm />
          <DividerWithText hidden mt="6">
            or continue with
          </DividerWithText>
          <SimpleGrid hidden mt="6" columns={3} spacing="3">
            <Button
              color="currentColor"
              variant="outline"
              onClick={() => handleOAuthSignIn('facebook')}
            >
              <VisuallyHidden>Login with Facebook</VisuallyHidden>
              <FaFacebook />
            </Button>
            <Button
              color="currentColor"
              variant="outline"
              onClick={() => handleOAuthSignIn('google')}
            >
              <VisuallyHidden>Login with Google</VisuallyHidden>
              <FaGoogle />
            </Button>
            <Button
              color="currentColor"
              variant="outline"
              onClick={() => handleOAuthSignIn('github')}
            >
              <VisuallyHidden>Login with Github</VisuallyHidden>
              <FaGithub />
            </Button>
          </SimpleGrid>
        </Card>
      </Box>
    </Box>
  )
}
export default SignIn

import {
  Alert,
  AlertIcon,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HTMLChakraProps,
  Input,
  Stack,
} from '@chakra-ui/react'
import { useUser } from '@utils/useUser'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PasswordField } from './ui/PasswordField'

export const LoginForm = (props: HTMLChakraProps<'form'>) => {
  const router = useRouter()
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()
  const { signIn } = useUser()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const onFormSubmit = async (formData: { email: string; password: string }) => {
    const { email, password } = formData

    const { error } = await signIn({ email, password })

    if (error) return setErrorMessage(error?.message)

    if (!error) {
      const param = new URLSearchParams(location.search)

      if (!param.has('invite_id')) router.push('/dashboard')

      for (const [key, invite_id] of param.entries()) {
        if (key === 'invite_id') {
          console.log(key, invite_id)
          const inviteUrl = `${location.origin}/invite/${invite_id}`
          router.push(inviteUrl)
        }
      }
      router.push('/dashboard')
    }
  }

  return (
    <chakra.form onSubmit={handleSubmit(onFormSubmit)} {...props}>
      {errorMessage && (
        <Alert my={4} borderRadius={5} status="error">
          <AlertIcon />
          {errorMessage}
        </Alert>
      )}
      <Stack spacing="6">
        <FormControl id="email" isInvalid={errors.name}>
          <FormLabel>Email address</FormLabel>
          <Input
            type="email"
            autoComplete="email"
            required
            display="flex"
            flexDir="column"
            {...register('email', { required: true })}
          />
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>
        <PasswordField register={register} errors={errors} />

        <Button
          type="submit"
          color="white"
          bgColor="brand.lightGreen"
          size="lg"
          fontSize="md"
          isLoading={isSubmitting}
          _hover={{ bg: 'brand.lightGreen' }}
        >
          Sign in
        </Button>
      </Stack>
    </chakra.form>
  )
}

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react'
import * as React from 'react'
import { DeepMap, FieldError, FieldValues, UseFormRegister } from 'react-hook-form'
import { HiEye, HiEyeOff } from 'react-icons/hi'

interface PasswordProps extends InputProps {
  register: UseFormRegister<FieldValues>
  errors: DeepMap<FieldValues, FieldError>
}

export const PasswordField = React.forwardRef<HTMLInputElement, PasswordProps>(
  ({ register, errors, ...props }, ref) => {
    const { isOpen, onToggle } = useDisclosure()
    const inputRef = React.useRef<HTMLInputElement>(null)

    const mergeRef = useMergeRefs(inputRef, ref)

    const onClickReveal = () => {
      onToggle()
      const input = inputRef.current
      if (input) {
        input.focus({ preventScroll: true })
        const length = input.value.length * 2
        requestAnimationFrame(() => {
          input.setSelectionRange(length, length)
        })
      }
    }

    return (
      <FormControl id="password" isInvalid={errors.password}>
        <Flex justify="space-between">
          <FormLabel>Password</FormLabel>
          <Box
            hidden
            as="a"
            color={'brand.lightGreen'}
            _hover={{ color: 'brand.darkGreen' }}
            fontWeight="semibold"
            fontSize="sm"
            cursor="pointer"
          >
            Forgot Password?
          </Box>
        </Flex>
        <InputGroup>
          <InputRightElement>
            <IconButton
              bg="transparent !important"
              variant="ghost"
              aria-label={isOpen ? 'Mask password' : 'Reveal password'}
              icon={isOpen ? <HiEyeOff /> : <HiEye />}
              onClick={onClickReveal}
            />
          </InputRightElement>
          <Input
            type={isOpen ? 'text' : 'password'}
            autoComplete="current-password"
            required
            flexDir="column"
            display="flex"
            {...register('password', { required: true, minLength: 8 })}
            {...props}
          />
          <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
        </InputGroup>
      </FormControl>
    )
  },
)

PasswordField.displayName = 'PasswordField'
